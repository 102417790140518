import { render, staticRenderFns } from "./UpgradeSubscriptionModal.vue?vue&type=template&id=48047231&scoped=true"
import script from "./UpgradeSubscriptionModal.vue?vue&type=script&lang=js"
export * from "./UpgradeSubscriptionModal.vue?vue&type=script&lang=js"
import style0 from "./UpgradeSubscriptionModal.vue?vue&type=style&index=0&id=48047231&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48047231",
  null
  
)

export default component.exports